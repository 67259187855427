import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { DialogUtility } from '@syncfusion/ej2-popups';

let infiniteLoopDetected = false;

export const InfiniteLoopHelper = () => {
    const history = useHistory();

    const showMessage = () => {
        console.info("InfiniteLoopHelper::showMessage starts");

        DialogUtility.alert({
            title: 'System error',
            content: "Please login and try again.\nIf the issue persist, please contact your administrator.",
            okButton: { text: 'OK' },
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
            close: () => {
                history.push('/authentication/login');
                infiniteLoopDetected = false;
            }
        });
    }

    useEffect(() => {
        if (infiniteLoopDetected) {
            showMessage();
        }

    }, [infiniteLoopDetected]);

    return (<div />);
}

class Stopwatch {
    constructor(sTime) {
        this.startTime = sTime;
        this.endTime = null;
        this.duration = 0;
    }

    start() {
        this.startTime = new Date();
    }

    stop() {
        if (!this.startTime) {
            this.duration = NaN;

            console.error("Unable to track http infinit loop: Invalid start time.");

            return this.duration;
        }

        this.endTime = new Date();
        this.duration = (this.endTime - this.startTime);

        return this.duration;
    }
}

class Tracker {
    constructor() {
        this.startTime = null;
        this.suspectRequests = 0;
    }
}

export class InfiniteLoopTracker {
    start() {
        sessionStorage.setItem("tracker", JSON.stringify(new Tracker()));
    }

    getTrackerObj() {
        let tracker = null;

        try {
            tracker = sessionStorage.getItem("tracker") === undefined || sessionStorage.getItem("tracker") === 'undefined' ?
                null : JSON.parse(sessionStorage.getItem("tracker"));
        }
        catch (e) {
            console.error("InfiniteLoopTracker::getTrackerObj - Error parsing invalid session storage object", e);

            tracker = null;
        }

        if (!tracker) {
            return new Tracker();
        }

        tracker.startTime = new Date(tracker.startTime);

        return tracker;
    }

    setTrackerObj(tracker) {
        sessionStorage.setItem("tracker", JSON.stringify(tracker));
    }

    check() {
        let detected = false;

        const tracker = this.getTrackerObj();

        if (!tracker.startTime) {
            tracker.startTime = new Date();

            this.setTrackerObj(tracker);

            return false;
        }

        let sw = new Stopwatch(tracker.startTime);

        const duration = sw.stop();

        if (isNaN(duration)) {
            console.error("Unable to track infinite loop: Invalid duration.");
            return false;
        }

        if (duration < 1000) {
            tracker.suspectRequests++;

            if (tracker.suspectRequests > 10) {
                infiniteLoopDetected = true;

                detected = true;;
            }
        }
        else if (tracker.suspectRequests < 10) {
            tracker.suspectRequests = 0;
        }

        tracker.startTime = new Date();

        this.setTrackerObj(tracker);

        return detected;
    }
}