import { ActionTypeList } from '../constants/index'

const initialState = {
    loading: false,
    accountSetting: {},
    error: ""
};

export const accountSettingPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SETTING_FETCHED:
            return {
                loading: false,
                accountSetting: action.payload,
                error: ""
            };
        case ActionTypeList.LOGIN_USER_UPDATE:
            return {
                ...state,
                error: action.payload,
            };
        case ActionTypeList.ACCOUNT_SETTING_FIELD_UPDATED:
            const payload = action.payload;

            return {
                loading: false,
                accountSetting: { ...state.accountSetting, [payload.fieldName]: payload.fieldValue },
                error: ""
            };
        default:
            return state;
    }
}


const initialAccountSettingPageState = {
    isTargetDetailSectionVisible: false,
};

export const accountSettingPageStatusPayload = (state = initialAccountSettingPageState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_DETAIL_SHOW_NEW_SCREEN:
            return {
                ...state,
                isTargetDetailSectionVisible: true,
            };
        case ActionTypeList.TARGET_SETTING_INSERTED:
        case ActionTypeList.TARGET_DETAIL_CLOSE_NEW_SCREEN:
        //case ActionTypeList.TARGET_SETTING_ACTIVATED:
            return {
                ...state,
                isTargetDetailSectionVisible: false,
            };
        //case ActionTypeList.TARGET_DETAIL_SHOW_ACTIVATION_SCREEN:
        //    return {
        //        ...state,
        //        toShowActivationScreen: true,
        //    };
        default:
            return state;
    }
}

const initialAccountSettingPageMessageBoxState = {
    visible: false,
    content: "",
    title: ""
};

export const accountSettingPageMessageBoxStatusPayload = (state = initialAccountSettingPageMessageBoxState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_INSERTED:
        //case ActionTypeList.TARGET_SETTING_ACTIVATED:

            return {
                ...state,
                visible: true,
                title: "Success",
                content: action.payload,
            };
        case ActionTypeList.TARGET_SETTING_INSERT:
        //case ActionTypeList.TARGET_SETTING_ACTIVATE:
            return {
                ...state,
                visible: true,
                title: "Error",
                content: action?.payload?.data?.error ?? ""
            };
        case ActionTypeList.CLOSE_MESSAGE_BOX:

            return {
                ...initialAccountSettingPageMessageBoxState
            };
        default:
            return state;
    }
}

const initialTargetDetailPageState = {
    name: "",
    url: "",
    description: "",
    includeLicense: false,
};

export const targetDetailPageState = (state = initialTargetDetailPageState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_DETAIL_PAGE_UPDATE_FIELD:
            const payload = action.payload;

            return {
                ...state,
                [payload.fieldName]: payload.fieldValue
            };
        case ActionTypeList.TARGET_SETTING_INSERTED:
        case ActionTypeList.TARGET_SETTING_ACTIVATED:
            return {
                ...initialTargetDetailPageState
            };
        default:
            return state;
    }
}