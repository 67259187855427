import { LocalNotifications } from "../../views/notifications/LocalNotifications";
import { dashboardData } from "../../_mock/dashboardData";
import {
    LOGO_BG,
    NAVBAR_BG,
    SIDEBAR_BG,
    THEME,
    DIRECTION,
    SIDEBAR_POSITION,
    HEADER_POSITION,
    LAYOUT,
    SIDEBAR_TYPE,
    CURRENT_TARGET,
    GRID_SELECTED_INDEX,
    CURRENT_USER,
    PALETTES,
    TARGETS_DATA,
    CURRENT_ISSUE,
    CURRENT_AUTH_METHOD,
    CURRENT_NOTIFICATIONS,
    LOGGED_IN,
    //RECENT_SCAN_PAGE
} from "../constants/";

const INIT_STATE = {
    activeDir: "ltr",
    activeThemeLayout: "vertical",
    activeTheme: "light",
    activeSidebarType: "full",
    activeLogoBg: "skin6",
    activeNavbarBg: "skin6",
    activeSidebarBg: "skin6",
    activeSidebarPos: "fixed",
    activeHeaderPos: "fixed",
    activeLayout: "full",
    currentTarget: null,
    palettes: ["#3399FF", "#F6B53F", "#E94649"],
    targetsData: dashboardData.targetsData,
    currentIssue: null,
    currentAuthMethod: -1,
    currentNotifications: new LocalNotifications(),
    loggedIn: false,
    //recentScanPage: null,
};

const SettingsReducer = (state = INIT_STATE, action) => {
    //console.log(`${new Date()}: SettingsReducer action type ${action.type} state ${JSON.stringify(state)} action.payload ${JSON.stringify(action.payload)}`);
    let result = {};

    switch (action.type) {
        //case RECENT_SCAN_PAGE:
        //    result =  {
        //        ...state,
        //        recentScanPage: action.payload,
        //    };
        //    break;
        case LOGGED_IN:
            result =  {
                ...state,
                loggedIn: action.payload,
            };
            break;
        case TARGETS_DATA:
            result =  {
                ...state,
                targetsData: action.payload,
            };
            break;
        case PALETTES:
            result =  {
                ...state,
                palettes: action.payload,
            };
            break;
        case CURRENT_TARGET:
            result = {
                ...state,
                currentTarget: action.payload,
            };
            break;
        case GRID_SELECTED_INDEX:
            console.log(`${new Date()}: SettingsReducer action type ${action.type}, action payload ${action.payload}`)
            result = {
                ...state,
                gridSelectedIndex: action.payload,
            };
            break;
        case CURRENT_USER:
            result =  {
                ...state,
                currentUser: action.payload,
            };
            break;
        case CURRENT_ISSUE:
            result =  {
                ...state,
                currentIssue: action.payload,
            };
            break;
        case CURRENT_AUTH_METHOD:
            result =  {
                ...state,
                currentAuthMethod: action.payload,
            };
            break;
        case CURRENT_NOTIFICATIONS:
            result =  {
                ...state,
                currentNotifications: action.payload,
            };
            break;
        case LOGO_BG:
            result =  {
                ...state,
                activeLogoBg: action.payload,
            };
            break;
        case NAVBAR_BG:
            result =  {
                ...state,
                activeNavbarBg: action.payload,
            };
            break;
        case SIDEBAR_BG:
            result =  {
                ...state,
                activeSidebarBg: action.payload,
            };
            break;
        case THEME:
            result =  {
                ...state,
                activeTheme: action.payload,
            };
            break;
        case DIRECTION:
            result =  {
                ...state,
                activeDir: action.payload,
            };
            break;
        case SIDEBAR_POSITION:
            result =  {
                ...state,
                activeSidebarPos: action.payload,
            };
            break;
        case HEADER_POSITION:
            result =  {
                ...state,
                activeHeaderPos: action.payload,
            };
            break;
        case LAYOUT:
            result =  {
                ...state,
                activeLayout: action.payload,
            };
            break;
        case SIDEBAR_TYPE:
            result =  {
                ...state,
                activeSidebarType: action.payload,
            };
            break;
        default:
            result = state;
            break;
    }

    //console.log(`${new Date()}: SettingsReducer action type ${action.type} final state ${JSON.stringify(result)} action.payload ${JSON.stringify(action.payload)}`);

    return result;
};

export default SettingsReducer;
