import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: null,
    error: "",
};

const scanQueueSettingUpdatePayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.SCAN_QUEUE_SETTING_UPDATED:
            return {
                loading: false,
                isSuccess: true,
                error: ""
            };
        case ActionTypeList.SCAN_QUEUE_SETTING_UPDATE: 
            return {
                loading: true,
                isSuccess: false,
                error: action.payload.data.error
            };
        default:
            return state;
    }
}

export default scanQueueSettingUpdatePayload;