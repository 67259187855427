import { ActionTypeList } from '../../constants'

const initialState = false;

const editUserFlagPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_SETTING_EDIT_MODE_UPDATED:
            return action.payload;
        case ActionTypeList.USER_SETTING_INSERTED:
            return !state;
        case ActionTypeList.USER_SETTING_CURRENT_USER_ID_UPDATED:
        case ActionTypeList.USER_SETTING_NEW_FLAG_TOGGLED:
        case ActionTypeList.USER_SETTING_UPDATED:
            return false;
        case ActionTypeList.USER_SETTING_UPDATE_EDIT_MODE:
            return state;
        default:
            return state;
    }
}

export default editUserFlagPayload;