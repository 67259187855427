import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: false,
    successMessage: '',
    toShowMessage: false,
    toShowConfirmMessageBeforeAction: false,
    error: "",
};

const targetDeactivationStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_DEACTIVATED:
            return {
                ...state,
                loading: false,
                isSuccess: true,
                successMessage: action.payload,
                toShowMessage: true,
                toShowConfirmMessageBeforeAction: false,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_DEACTIVATE:
            return {
                ...state,
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                toShowConfirmMessageBeforeAction: false,
                error: action.payload.data.error
            };
        case ActionTypeList.TARGET_SETTING_DEACTIVATED_HIDE_MSG:
            return {
                ...state,
                loading: false,
                toShowMessage: false,
                isSuccess: false,
                toShowConfirmMessageBeforeAction: false,
                error: "",
                successMessage: ``
            };
        case ActionTypeList.ACCOUNT_TARGET_SETTING_DEACTIVATION_MODE_INITIATED:
            return {
                ...state,
                toShowConfirmMessageBeforeAction: true,
            }
        default:
            return state;
    }
}

export default targetDeactivationStatusPayload;