import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: null,
    error: "",
};

const accountSettingUpdateStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SETTING_UPDATED:
            return {
                loading: false,
                isSuccess: true,
                error: ""
            };
        case ActionTypeList.ACCOUNT_SETTING_UPDATE:
            return {
                loading: true,
                isSuccess: false,
                error: action.payload.data.error
            };
        case ActionTypeList.ACCOUNT_SETTING_UPDATE_STATUS_RESET:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

export default accountSettingUpdateStatusPayload;