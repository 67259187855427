import { ActionTypeList } from '../../constants'

const initialState = {
    currentUserId: ''
};

const currentUserIdPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_SETTING_CURRENT_USER_ID_UPDATED:
            return action.payload;
        default:
            return state;
    }
}

export default currentUserIdPayload;