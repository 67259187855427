import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: false,
    successMessage: '',
    toShowMessage: false,
    error: "",
};

const targetActivationStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_ACTIVATED:
            return {
                loading: false,
                isSuccess: true,
                successMessage: action.payload,
                toShowMessage: true,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_ACTIVATE:
            return {
                ...state,
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: action.payload.data.error
            };
        //case ActionTypeList.TARGET_SETTING_ACTIVATED_HIDE_MSG:
        //    return {
        //        loading: false,
        //        toShowMessage: false,
        //        isSuccess: false,
        //        error: "",
        //        successMessage: ``
        //    };
        default:
            return state;
    }
}

export default targetActivationStatusPayload;