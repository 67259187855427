import { ActionTypeList } from '../constants/index'

const initialState = {
    loading: false,
    detail: {},
    error: ""
};

export const userAccountActivationDetailReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_ACCOUNT_ACTIVATION_FETCHED:
            return {
                loading: false,
                detail: action.payload,
                error: ""
            };
        case ActionTypeList.USER_ACCOUNT_ACTIVATION_FETCH:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}