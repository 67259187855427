import { ActionTypeList } from '../constants/index'
import DOMPurify from 'dompurify';

const initialLatestTosState = {
    loading: false,
    latestTos: {},
    error: ""
};

export const latestTosReducer = (state = initialLatestTosState, action) => {

    switch (action.type) {
        case ActionTypeList.TOS_LATEST_FETCHED:
            const { version, publishedOn, effectiveDate, description } = action.payload;

            return {
                loading: false,
                latestTos: {
                    version, publishedOn, effectiveDate, description,
                },
                error: ""
            };
        case ActionTypeList.TOS_LATEST_FETCH:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

const initialTosState = {
    loading: false,
    tos: {},
    error: ""
};

export const tosReducer = (state = initialTosState, action) => {

    switch (action.type) {
        case ActionTypeList.TOS_FETCHED:
            const { version, publishedOn, effectiveDate, description } = action.payload;

            return {
                loading: false,
                tos: {
                    version, publishedOn, effectiveDate, description,
                },
                error: ""
            };
        case ActionTypeList.TOS_FETCH:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

const initialTosAcceptanceSubmissionState = {
    loading: false,
    tosAcceptanceSubmission: null,
    error: ""
};


export const tosAcceptanceSubmissionReducer = (state = initialTosAcceptanceSubmissionState, action) => {

    switch (action.type) {
        case ActionTypeList.TOS_ACCEPTANCE_SUBMITTED:
            const { permissionsAcceptance, acceptResult } = action.payload;

            return {
                loading: false,
                tosAcceptanceSubmission: { permissionsAcceptance, acceptResult},
                error: ""
            };
        case ActionTypeList.TOS_ACCEPTANCE_SUBMIT:
            return {
                ...state,
                error: action.payload,
            };
        case ActionTypeList.LOGIN_PAGE_VISIT:
            return initialTosAcceptanceSubmissionState;
        default:
            return state;
    }
}