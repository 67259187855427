import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    isSuccess: false,
    successMessage: '',
    toShowMessage: false,
    error: "",
};

const userInsertStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_SETTING_INSERTED:
            return {
                loading: false,
                isSuccess: true,
                successMessage: action.payload,
                toShowMessage: true,
                error: ""
            };
        case ActionTypeList.USER_SETTING_INSERT:
            return {
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: action.payload
            };
        case ActionTypeList.USER_SETTING_INSERTED_HIDE_MSG:
            return {
                loading: false,
                toShowMessage: false,
                isSuccess: false,
                error: "",
                successMessage: ``
            };
        default:
            return state;
    }
}

export default userInsertStatusPayload;