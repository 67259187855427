import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    scanQueueSetting: null,
    error: "",
};


const fetchScanQueueSettingPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.SCAN_QUEUE_SETTING_DATA_FETCHED:
            return {
                loading: false,
                scanQueueSetting: action.payload,
                error: ""
            };
        case ActionTypeList.SCAN_QUEUE_SETTING_DATA_FETCH:
            return {
                loading: true,
                scanQueueSetting: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default fetchScanQueueSettingPayload;
