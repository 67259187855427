import { ActionTypeList } from '../../constants'

const initialState = {
    showPasswordResetPage: false,
    isCallerFromUserSettingPage: false
};

const resetPasswordPageLoadStatusPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.USER_SETTING_RESET_PASSWORD_PAGE_LOADED:
        case ActionTypeList.USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED:
            return action.payload;
        default:
            return state;
    }
}

export default resetPasswordPageLoadStatusPayload;