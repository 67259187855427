import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    data: null,
    error: "",
};

const assigneeUpdateStatusPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.ASSIGNEE_UPDATED:
            return {
                loading: false,
                data: action.payload,
                error: ""
            };
        case ActionTypeList.ASSIGNEE_UPDATE:
            return {
                loading: true,
                data: action.payload,
                error: action.payload
            };
        case ActionTypeList.ASSIGNEE_UPDATE_STATUS_RESET:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

export default assigneeUpdateStatusPayload;